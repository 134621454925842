exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cedrowe-serce-js": () => import("./../../../src/pages/cedrowe-serce.js" /* webpackChunkName: "component---src-pages-cedrowe-serce-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-marzenia-skarbonki-js": () => import("./../../../src/pages/marzenia-skarbonki.js" /* webpackChunkName: "component---src-pages-marzenia-skarbonki-js" */),
  "component---src-pages-mgla-o-szklanym-sercu-js": () => import("./../../../src/pages/mgla-o-szklanym-sercu.js" /* webpackChunkName: "component---src-pages-mgla-o-szklanym-sercu-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-radio-kot-js": () => import("./../../../src/pages/radio-kot.js" /* webpackChunkName: "component---src-pages-radio-kot-js" */),
  "component---src-pages-sluchowiska-js": () => import("./../../../src/pages/sluchowiska.js" /* webpackChunkName: "component---src-pages-sluchowiska-js" */),
  "component---src-pages-tymiankowy-czarodziej-js": () => import("./../../../src/pages/tymiankowy-czarodziej.js" /* webpackChunkName: "component---src-pages-tymiankowy-czarodziej-js" */),
  "component---src-pages-uczestnicy-projektu-js": () => import("./../../../src/pages/uczestnicy-projektu.js" /* webpackChunkName: "component---src-pages-uczestnicy-projektu-js" */),
  "component---src-pages-wera-bazgroli-js": () => import("./../../../src/pages/wera-bazgroli.js" /* webpackChunkName: "component---src-pages-wera-bazgroli-js" */),
  "component---src-pages-zagubione-slowa-bibliotekarza-makarego-js": () => import("./../../../src/pages/zagubione-slowa-bibliotekarza-makarego.js" /* webpackChunkName: "component---src-pages-zagubione-slowa-bibliotekarza-makarego-js" */)
}

